document.addEventListener('DOMContentLoaded', function() {
    const menuTrigger = document.getElementById('mobile-menu-trigger');
    const navContainer = document.querySelector('.nav-container');
    const header = document.querySelector('header');
    let isMenuOpen = false;

    function toggleMenu(open) {
        isMenuOpen = open;
        menuTrigger.classList.toggle('active', open);
        menuTrigger.setAttribute('aria-expanded', open);
        
        // Add/remove 'active' class to header
        header.classList.toggle('active', open);

        if (open) {
            navContainer.style.opacity = '1';
            navContainer.style.pointerEvents = 'auto';
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            navContainer.style.opacity = '0';
            navContainer.style.pointerEvents = 'none';
            document.body.style.overflow = '';
            document.body.style.height = '';
            document.documentElement.style.overflow = '';
            document.documentElement.style.height = '';
        }
    }

    menuTrigger.addEventListener('click', function(e) {
        e.preventDefault();
        toggleMenu(!isMenuOpen);
    });

    // Close menu when clicking on nav links
    const navLinks = navContainer.querySelectorAll('a');
    navLinks.forEach(link => {
        link.addEventListener('click', () => {
            if (window.innerWidth <= 1024) {
                toggleMenu(false);
            }
        });
    });

    // Close menu when clicking outside
    document.addEventListener('click', function(e) {
        if (isMenuOpen && 
            !navContainer.contains(e.target) && 
            !menuTrigger.contains(e.target)) {
            toggleMenu(false);
        }
    });

    // Handle window resize
    window.addEventListener('resize', () => {
        if (window.innerWidth > 1024) {
            navContainer.style.removeProperty('opacity');
            navContainer.style.removeProperty('pointer-events');
            document.body.style.removeProperty('overflow');
            document.body.style.removeProperty('height');
            document.documentElement.style.removeProperty('overflow');
            document.documentElement.style.removeProperty('height');
            menuTrigger.classList.remove('active');
            header.classList.remove('active');
            isMenuOpen = false;
        } else if (!isMenuOpen) {
            navContainer.style.opacity = '0';
            navContainer.style.pointerEvents = 'none';
        }
    });
});